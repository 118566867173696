'use client';

import { PLATFORM_LANGUAGES } from '@company/common/types';
import { AuthUser } from '@typings/auth-user';
import React from 'react';

interface AuthUserContextProps {
  authUser: AuthUser;
  localeCode: 'de' | 'en';
  isAdmin: boolean;
}

const UserContext = React.createContext<AuthUserContextProps>({
  authUser: {
    id: '',
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    organization: {
      id: '',
      name: '',
      legalName: ''
    },
    canViewBilling: false,
    teamIds: [],
    status: 'ACTIVE',
    role: 'MEMBER',
    type: 'HUMAN',
    language: 'DE',
    avatarColor: null,
    avatarUrl: null
  },
  localeCode: 'de',
  isAdmin: false
});
export const useAuthUser = () => React.useContext(UserContext);

export const AuthProvider = ({
  authUser,
  children
}: {
  authUser: AuthUser;
  children: React.ReactNode;
}) => {
  const value = React.useMemo(
    () => ({
      authUser,
      localeCode: PLATFORM_LANGUAGES[authUser.language].localeCode,
      isAdmin: authUser.role === 'ADMIN' || authUser.role === 'SUPER_ADMIN'
    }),
    [authUser]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
