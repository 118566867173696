'use client';

import { Container, Heading, LinkButton, Text } from '@company/ui/components';
import { Trans } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';

export const Error = ({ error }: { error?: string | null }) => {
  const { authUser } = useAuthUser();
  return (
    <Container h={'full'} py={10}>
      <Heading size={'md'} mb={8}>
        <Trans>Something is not right...</Trans>
      </Heading>

      {error && <Text mb={3}>{error}</Text>}

      <LinkButton href={authUser ? '/home' : '/sign-in'} variant="outline" size="md" mt="xl">
        <Trans>Get back to home</Trans>
      </LinkButton>
    </Container>
  );
};
